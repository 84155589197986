<div
    class="bazis-modal-wrap"
    *transloco="let t"
>
    <ng-container
        *ngIf="{
            reports: reports$ | async,
            form: form$ | async,
            changes: changes$ | async,
        } as data"
    >
        <div class="bazis-modal__header">
            <h1 class="bh-no-margin">{{ t('analytics.modal.h1') }}</h1>
        </div>
        <div class="bazis-modal__content">
            <ng-container *ngIf="data.reports">
                <bazis-select
                    [formControl]="reportType"
                    [options]="data.reports"
                    [multiple]="false"
                    [required$]="required$"
                    titleKey="analytics.modal.typeReport"
                ></bazis-select>
                <form
                    *ngIf="data.form && reportType.value"
                    [formGroup]="data.form"
                >
                    <bazis-grid class="bazis-grid_form">
                        <bazis-row *ifControl="data.form.controls['dt']">
                            <bazis-col>
                                <bazis-input-date formControlName="dt"></bazis-input-date>
                            </bazis-col>
                        </bazis-row>
                        <ng-container
                            *ifControl="[
                                data.form.controls['dt_start'],
                                data.form.controls['dt_finish']
                            ]"
                            ifControlCondition="or"
                        >
                            <bazis-row>
                                <bazis-col>
                                    <bazis-label
                                        [title]="t('analytics.modal.datePeriodReport')"
                                        [required]="isDateRequired"
                                    >
                                    </bazis-label>
                                </bazis-col>
                            </bazis-row>
                            <bazis-row>
                                <bazis-col *ifControl="data.form.controls['dt_start']">
                                    <bazis-input-date
                                        [ngClass]="{
                                            'sl-control_no-margin':
                                                data.form?.errors?.invalidIntervalDate
                                        }"
                                        formControlName="dt_start"
                                    ></bazis-input-date>
                                </bazis-col>
                                <bazis-col *ifControl="data.form.controls['dt_finish']">
                                    <bazis-input-date
                                        [ngClass]="{
                                            'sl-control_no-margin':
                                                data.form?.errors?.invalidIntervalDate
                                        }"
                                        formControlName="dt_finish"
                                    ></bazis-input-date>
                                </bazis-col>
                            </bazis-row>
                            <bazis-row *ngIf="data.form?.errors?.invalidIntervalDate">
                                <bazis-col class="bh-margin-bottom-6x">
                                    <bazis-display-error errorKey="invalidIntervalDate">
                                    </bazis-display-error>
                                </bazis-col>
                            </bazis-row>
                        </ng-container>
                        <bazis-row *ifControl="data.form.controls['region']">
                            <bazis-col>
                                <bazis-autocomplete formControlName="region"></bazis-autocomplete>
                            </bazis-col>
                        </bazis-row>
                        <bazis-row
                            *ifControl="[
                                data.form.controls['loading_region'],
                                data.form.controls['unloading_region']
                            ]"
                            ifControlCondition="or"
                        >
                            <bazis-col *ifControl="data.form.controls['loading_region']">
                                <bazis-autocomplete formControlName="loading_region"></bazis-autocomplete>
                            </bazis-col>
                            <bazis-col *ifControl="data.form.controls['unloading_region']">
                                <bazis-autocomplete formControlName="unloading_region"></bazis-autocomplete>
                            </bazis-col>
                        </bazis-row>
                    </bazis-grid>
                </form>
            </ng-container>
        </div>
        <div class="bazis-modal__footer">
            <div class="bh-line-elements">
                <bazis-button
                    color="action"
                    (click)="generate$.next(true)"
                    [disabled]="
                        !data.form ||
                        data.form.status !== 'VALID' ||
                        !reportType.value ||
                        (generate$ | async) === true
                    "
                >
                    {{
                        (generate$ | async) === true
                            ? t('analytics.modal.generating')
                            : t('analytics.modal.generateReport')
                    }}

                    <span
                        *ngIf="(generate$ | async) === true"
                        class="bazis-loader_dots"
                    ></span>
                </bazis-button>
                <bazis-button
                    color="action"
                    fill="clear"
                    (click)="close()"
                >
                    {{ t('analytics.modal.cancel') }}
                </bazis-button>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="{ generation: generation$ | async } as data"></ng-container>
</div>
