<div
    class="bazis-control"
    [class.bazis-control--focused]="isFocused"
    [class.bazis-control--dirty]="ngControl.control.value"
    *transloco="let t"
>
    <ng-container
        *ngIf="{
            required: required$ | async,
            valueChanges: valueChanges$ | async,
            statusChanges: statusChanges$ | async
        } as data"
    >
        <bazis-label
            [isMajor]="hasTitleMajor"
            [isEmptyLabel]="isEmptyLabel"
            [title]="title || t(titleKey, titleParams)"
            [required]="data.required"
        >
            <bazis-interrogation
                *ngIf="tooltipKey"
                [slot]="tooltipSettings?.targetSide"
                iconSize="xs"
                [tooltipText]="t(tooltipKey, tooltipParams)"
                [tooltipOffset]="[-8, 4]"
                [tooltipEventType]="tooltipSettings?.eventType || 'hover'"
                [tooltipPlacement]="tooltipSettings?.placement || 'top-start'"
            ></bazis-interrogation>
        </bazis-label>

        <div
            class="bazis-control__field-group"
            [class.bazis-control__field-group--disabled]="field.disabled"
            [class.bazis-control__field-group--focused]="isFocused"
        >
            <bazis-control-field-start
                [isClickable]="isLeftIconClickable"
                [icon]="leftIcon"
                [tooltipText]="t(tooltipKey, tooltipParams)"
                [tooltipSettings]="tooltipSettings"
                (actionClick)="onLeftIconClick($event)"
            >
            </bazis-control-field-start>

            <div
                class="bazis-control__field-wrapper"
                #fieldWrapRef
            >
                <input
                    *ngIf="!maskSettings && rows === 1"
                    [type]="type"
                    class="bazis-control__field"
                    [ngClass]="{
                        'bh-no-padding': withoutInnerPadding,
                        'bazis-control__field--erasable': hasEraser
                    }"
                    [placeholder]="
                        placeholder ? placeholder : placeholderKey ? t(placeholderKey) : ''
                    "
                    (focus)="focusField($event)"
                    (blur)="blurField($event)"
                    [formControl]="field"
                    [maxlength]="maxLength"
                />
                <textarea
                    *ngIf="!maskSettings && rows > 1"
                    class="bazis-control__field"
                    [placeholder]="
                        placeholder ? placeholder : placeholderKey ? t(placeholderKey) : ''
                    "
                    (focus)="focusField($event)"
                    (blur)="blurField($event)"
                    [formControl]="field"
                    [maxlength]="maxLength"
                ></textarea>
                <input
                    *ngIf="maskSettings"
                    [type]="type"
                    class="bazis-control__field"
                    [class.bh-no-padding]="withoutInnerPadding"
                    [placeholder]="
                        placeholder ? placeholder : placeholderKey ? t(placeholderKey) : ''
                    "
                    (focus)="focusField($event)"
                    (blur)="blurField($event)"
                    [formControl]="field"
                    [maxlength]="maxLength"
                    [mask]="maskSettings.mask"
                    [suffix]="maskSettings.suffix"
                    [prefix]="maskSettings.prefix"
                    [thousandSeparator]="maskSettings.thousandSeparator"
                    [decimalMarker]="maskSettings.decimalMarker"
                    [patterns]="maskSettings.patterns"
                    [clearIfNotMatch]="maskSettings.clearIfNotMatch"
                    [showMaskTyped]="maskSettings.showMaskTyped && isFocused"
                    [placeHolderCharacter]="maskSettings.placeHolderCharacter"
                    [dropSpecialCharacters]="maskSettings.dropSpecialCharacters"
                    [hiddenInput]="maskSettings.hiddenInput"
                    [shownMaskExpression]="maskSettings.shownMaskExpression"
                    [separatorLimit]="maskSettings.separatorLimit"
                    [allowNegativeNumbers]="maskSettings.allowNegativeNumbers"
                    [validation]="maskSettings.validation"
                    [specialCharacters]="maskSettings.specialCharacters"
                    [leadZeroDateTime]="maskSettings.leadZeroDateTime"
                    [apm]="maskSettings.apm"
                    [leadZero]="maskSettings.leadZero"
                    [keepCharacterPositions]="maskSettings.keepCharacterPositions"
                    [triggerOnMaskChange]="maskSettings.triggerOnMaskChange"
                    [inputTransformFn]="maskSettings.inputTransformFn"
                    [outputTransformFn]="maskSettings.outputTransformFn"
                />
            </div>

            <bazis-control-field-end
                #fieldEnd
                [isClickable]="isRightIconClickable"
                [icon]="rightIcon"
                [tooltipText]="t(tooltipKey, tooltipParams)"
                [tooltipSettings]="tooltipSettings"
                [unitText]="unit || t(unitKey)"
                [canEraserShow]="canEraserShow"
                [value]="ngControl.control.value"
                (actionClick)="onRightIconClick($event)"
                (erased)="onClear($event)"
            >
            </bazis-control-field-end>
        </div>

        <div class="bazis-control__error">
            <bazis-form-error
                [validationErrorMessages]="validationErrorMessages"
                [formControl]="field"
            ></bazis-form-error>
            <bazis-form-error
                *ngIf="field.status === 'VALID'"
                [validationErrorMessages]="validationErrorMessages"
                [formControl]="ngControl.control"
            ></bazis-form-error>

            <!-- для ошибок родительского контрола
            (пример использоавния в input-text-array, предполагается сюда "вложить" bazis-form-error) -->
            <ng-content></ng-content>
        </div>

        <div
            *ngIf="noteKey"
            class="bazis-control__note"
        >
            {{ t(noteKey, noteParams) }}
        </div>
        <ng-content select="note"></ng-content>
    </ng-container>
</div>
