import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    Self,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import { TooltipSettings, ValidationErrorMessages } from '@bazis/form/models/form-element.types';
import { Observable } from 'rxjs';
import { EntityFormControl } from '@bazis/form/models/form.types';
import { BazisFormService } from '@bazis/form/services/form.service';
import { SimpleData } from '@bazis/shared/models/srv.types';

@UntilDestroy()
@Component({
    selector: 'bazis-control-value-accessor',
    template: '',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BazisControlValueAccessor implements ControlValueAccessor, OnChanges, OnInit {
    // ** Start Inputs ** //
    // заголовок поля
    @Input() title: string = null;

    // заголовок поля
    @Input() titleKey: string = null;

    // параметры для title
    @Input() titleParams: any = null;

    // placeholder поля
    @Input() placeholder: string = '';

    // placeholder поля
    @Input() placeholderKey: string = '';

    // тултип поля
    @Input() tooltipKey: string = null;

    // параметры для tooltip
    @Input() tooltipParams: any = null;

    // настройки отображения и работы тултипа
    @Input() tooltipSettings: TooltipSettings = null;

    // возможно, нужен будет какой-то коммент под полем
    @Input() noteKey: string = null;

    // параметры для note
    @Input() noteParams: any = null;

    // ед. измерения справа
    @Input() unitKey: string = null;

    // ед. измерения справа
    @Input() unit: string = null;

    // иконка, которую надо вывести слева
    @Input() leftIcon: string = null;

    // является ли иконка слева кликабельной
    @Input() isLeftIconClickable: boolean = null;

    // иконка, которую надо вывести справа
    @Input() rightIcon: string = null;

    // является ли иконка справа кликабельной
    @Input() isRightIconClickable: boolean = null;

    // Что выводить в readonly, если значения нет
    @Input() emptyValue: string | SimpleData = '';

    // наличие стиралки для поля
    @Input() hasEraser: boolean = true;

    // отображение лейбла поля выделенным стилем
    @Input() hasTitleMajor: boolean = false;

    // пустой label, для сохранения отступа, когда поля в ряд.
    @Input() isEmptyLabel: boolean = false;

    // если label внутри поля (в одну строчку со значением)
    @Input() isInnerlabel: boolean = false;

    // обязательность поля
    @Input() required$: Observable<boolean> = null;

    // для кастомных текстов ошибок
    @Input() validationErrorMessages: ValidationErrorMessages = {};

    // ** Start Outputs ** //
    // событие по клику на правой иконке
    @Output() rightIconClick = new EventEmitter();

    // событие по клику на левой иконке
    @Output() leftIconClick = new EventEmitter();

    // для всплытия состояния
    @Output() touched = new EventEmitter();

    // событие на blur()
    @Output() blured = new EventEmitter();

    // событие на focus()
    @Output() focused = new EventEmitter();

    // событие на стиралку
    @Output() erased = new EventEmitter();

    // ** Start ViewChild ** //
    @ViewChild('fieldWrapRef') fieldWrapRef: ElementRef;

    public onChange = (_: any) => {};

    public onTouched = () => {};

    isTouched = false;

    isFocused = false;

    canEraserShow = false;

    constructor(@Self() public ngControl: NgControl, protected cdr: ChangeDetectorRef) {
        ngControl.valueAccessor = this;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.placeholder) {
            this.cdr.markForCheck();
        }
    }

    ngOnInit() {
        if (this.ngControl.control instanceof EntityFormControl) {
            BazisFormService.getPropertiesFromConfig(this, this.ngControl.control.$config);
        }

        this.extendOnInit();

        this.writeValue(this.ngControl.control.value);
    }

    public registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    public registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    public writeValue(value: any): void {}

    public markAsTouched() {
        if (!this.isTouched) {
            this.onTouched();
            this.isTouched = true;
            this.touched.emit(true);
        }
    }

    onLeftIconClick(e) {
        this.markAsTouched();
        this.leftIconClick.emit(e);
    }

    onRightIconClick(e) {
        this.markAsTouched();
        this.rightIconClick.emit(e);
    }

    onClear(e) {
        if (this.canEraserShow) {
            this.erased.emit(e);
        }
        this.ngControl.control.setValue(this.emptyValue || null);
    }

    blurField(e) {
        this.isFocused = false;
        this.blured.emit(e);
    }

    focusField(e) {
        this.markAsTouched();
        this.isFocused = true;

        this.focused.emit(e);
    }

    // действия внутри OnInit, между инициализации конфига и writeValue. для расширяющих контролов
    extendOnInit() {}
}
