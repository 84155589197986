import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    OnDestroy,
    Renderer2,
    Self,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { BazisControlValueAccessor } from '@bazis/form/components/control-value-accessor.component';
import { BazisEntityService } from '@bazis/shared/services/entity.service';
import { BazisMediaQueryService } from '@bazis/shared/services/media-query.service';
import { FormControl, NgControl } from '@angular/forms';
import { SearchSettings, SimpleData } from '@bazis/shared/models/srv.types';
import { TemplateObservable } from '@bazis/shared/classes/template-observable';
import { Observable, shareReplay } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { SHARE_REPLAY_SETTINGS } from '@bazis/configuration.service';
import { latinizeStr } from '@bazis/utils';

@Component({
    selector: 'bazis-autocomplete',
    templateUrl: './autocomplete.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BazisAutocompleteComponent
    extends BazisControlValueAccessor
    implements AfterViewInit, OnDestroy
{
    @Input() entityType: string = null;

    @Input() searchSettings: SearchSettings = null;

    // TODO: include in default template/logic
    @Input() staticOptions: SimpleData[];

    @Input() infiniteListTpl: TemplateRef<any>;

    // иконка, которую надо вывести справа
    // @Input() rightIcon: string; // добавить только для input-search= 'menu-burger';

    @Input() emptyValue: SimpleData = null;

    @Input() multiple: boolean = false;

    @Input() nameField: string = null; // use value from item.$snapshot[nameField] to display item in the list

    @Input() mapSettings: boolean = false;

    @Input() mapLayers: boolean = false;

    @Input() excludeIds$: Observable<string[]> = null;

    @Input() forceLatin: boolean = false; // force latin symbols in input field

    @ViewChild('searchInput') set searchInput(el: ElementRef) {
        if (!el) return;

        this.nativeSearchInput = el.nativeElement;

        this.renderer.listen(el.nativeElement, 'keydown.tab', (event) => {
            this.hideList();
        });
        // Тонкая настройка: "Смотреть можно, трогать — нет!"
        this.renderer.listen(this.nativeSearchInput, 'focus', (event) => {
            // this.nativeSearchInput.value = this.nativeSearchInput.value;
            // console.log('focus', this.nativeSearchInput.value.length);
            this.nativeSearchInput.selectionStart = this.nativeSearchInput.value.length;
            // console.log('focus', this.nativeSearchInput.value.length, this.nativeSearchInput.selectionStart);
        });
    }

    // внешний вид, secondary - серый
    @Input() type: 'default' | 'secondary' = 'default';

    // отображение иконки лупы, вместо птички дропдауна
    @Input() hasSearchIcon: boolean = false; // заменить через otherIcon

    @Input() selectedValueTpl: TemplateRef<any>;

    @Input() iconUp: string = 'caret-up';

    @Input() iconDown: string = 'caret-down';

    @Input() hasChangeLink = false;

    @Input() viewTypeSelected: 'tagsStart' | 'tagsEnd' | 'inside' | 'empty' = 'inside';

    nativeSearchInput;

    emptyValueEntity = null;

    protected _isInited = false;

    searchControl: FormControl<string | null> = new FormControl();

    values: TemplateObservable<string[]> = new TemplateObservable<string[]>([]);

    valuesMap: Map<any, boolean> = new Map([]);

    isDisabled = false;

    isVisibleField = true;

    showList: boolean = false;

    get staticOptionsEntities() {
        return this.staticOptions
            ? this.staticOptions.map((v) => this.entityService.toEntityItem(v))
            : [];
    }

    get staticOptionsEntitiesMap() {
        return this.staticOptionsEntities.reduce(
            (acc, current) => ({ ...acc, [current.id]: current }),
            {},
        );
    }

    searchValue$ = this.searchControl.valueChanges.pipe(
        map((value) => {
            if (this.forceLatin && value) {
                let newValue = latinizeStr(value.toUpperCase());
                if (value !== newValue) {
                    this.searchControl.setValue(newValue);
                    return undefined;
                }
            }
            return value;
        }),
        filter((v) => v !== undefined),
        shareReplay(SHARE_REPLAY_SETTINGS),
    );

    constructor(
        @Self() public ngControl: NgControl,
        protected cdr: ChangeDetectorRef,
        protected entityService: BazisEntityService,
        public mqSrv: BazisMediaQueryService,
        private renderer: Renderer2,
    ) {
        super(ngControl, cdr);
        ngControl.valueAccessor = this;
    }

    extendOnInit() {
        if (this.emptyValue) {
            this.emptyValueEntity = this.entityService.toEntityItem(this.emptyValue);
        }

        if ((!this.searchSettings || !this.searchSettings.entityType) && this.entityType) {
            this.searchSettings = {
                ...this.searchSettings,
                entityType: this.entityType,
            };
        }

        if (!this.entityType && this.searchSettings.entityType) {
            this.entityType = this.searchSettings.entityType;
        }

        this._isInited = true;
    }

    ngAfterViewInit(): void {
        if (this.hasChangeLink && this.mqSrv.inIntervalBreakpoint('xs', 'lg')) {
            this.isVisibleField = this.ngControl.value ? false : true;
        }
    }

    ngOnDestroy(): void {
        this.valuesMap.clear();
    }

    public setDisabledState(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
        if (isDisabled) this.searchControl.disable();
        else this.searchControl.enable();
    }

    public writeValue(value): void {
        if (!this._isInited) return;
        this.searchControl.setValue('');
        // может быть multiple === true, но value === null. Необходимо избежать появления [null]
        this.values.set(
            this.multiple && Array.isArray(value) ? value : !this.multiple ? [value] : [],
        );
        this.valuesMap.clear();
        if (this.multiple && Array.isArray(value)) {
            value.forEach((item) => this.valuesMap.set(item, true));
        } else if (!this.multiple) {
            this.valuesMap.set(value, true);
        }
    }

    toggleValue(id: string = '') {
        let value = this.values._;

        if (!id) {
            value = [];
        } else {
            if (value.indexOf(id) > -1) {
                value = value.filter((v) => v !== id);
                this.valuesMap.delete(id);
            } else {
                if (this.multiple) {
                    value.push(id);
                } else {
                    this.valuesMap.clear();
                    value = [id];
                }

                this.valuesMap.set(id, true);
            }
        }

        this.onChange(this.multiple ? [...value] : value[0] || null);
        this.values.set(value);

        this.markAsTouched();

        if (!this.multiple) {
            this.hideList();
        }

        this.searchControl.reset();
        this.mobileToggleList();
        // if (this.showList) this.showList = false; // закрывается лист при множественно выборе
    }

    toggleList() {
        if (!this.showList) {
            this.nativeSearchInput.focus();
        }
        this.mobileToggleList();
        if (this.showList && this.isFocused) return;
        this.showList = !this.showList;
        this.markAsTouched();
    }

    hideList() {
        if (!this.isFocused) this.showList = false;
    }

    onClear() {
        this.values._.forEach((val) => {
            this.toggleValue(val);
        });
        this.ngControl.control.setValue(this.emptyValue || (this.multiple ? [] : null));
    }

    showField(e) {
        this.isVisibleField = true;
        this.showList = true;
        this.isFocused = true;
        this.toggleList();
    }

    focusField() {
        this.isFocused = true;
        this.showList = true;
        this.isVisibleField = true;
    }

    mobileToggleList() {
        if (this.hasChangeLink && this.mqSrv.inIntervalBreakpoint('xs', 'lg')) {
            this.isVisibleField = this.ngControl.value ? false : true;
            this.isFocused = false;
        }
    }

    hideListMobile() {
        this.showList = false;
        this.mobileToggleList();
    }
}
